/* AdeptosPage.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.container {
    width: 90%; /* Alterado para uma porcentagem maior para maior flexibilidade */
    max-width: 1200px; /* Limita a largura máxima do contêiner para evitar que fique muito largo em telas grandes */
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
}

button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

ul li button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #dc3545;
}

ul li button:hover {
    background-color: #c82333;
}

ul li button:first-of-type {
    background-color: #28a745;
}

ul li button:first-of-type:hover {
    background-color: #218838;
}

table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto; /* Adicionado para permitir a rolagem horizontal em telas menores */
}

th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}
