/* CadastroObrigacaoPage.css */
body {
  font-family: Arial, sans-serif;
  background-color: rgba(0, 0, 0, 0.5); /* Escurece ligeiramente o fundo */
  margin: 10px;
  padding: 10px;
  padding-top: 50px;
  height: 90vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.container {
  width: 60%;
  max-width: 600px;
  margin: 20px; /* Adiciona margem */
  padding: 30px 20px 20px; /* Adiciona padding (topo, direita, inferior, esquerda) */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
}

h2 {
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
  font-size: 24px;
}

form {
  display: flex;
  flex-direction: column;
  margin: 1px solid;
}

form div {
  margin-bottom: 20px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555555;
}

input {
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

button {
  padding: 12px 20px;
  margin-top: 20px;
  background-color: #007BFF;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

button[type="submit"] {
  background-color: #28a745;
}

button[type="submit"]:hover {
  background-color: #218838;
}
